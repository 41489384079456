import { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import getLPTheme from './getLPTheme';
import './firebaseApp';
import './App.css';
import CookieConsent from './components/CookieConsent';

// Lazy load pages
const LandingPage = lazy(() => import('./LandingPage'));
const AboutPage = lazy(() => import('./views/About'));
const ContactPage = lazy(() => import('./views/Contact')); 
const RequestDemoPage = lazy(() => import('./views/RequestDemo'));
const FAQsPage = lazy(() => import('./views/Faqs'));
const BlogPage = lazy(() => import('./views/Blog'));
const CareersPage = lazy(() => import('./views/Careers'));
const TermsPage = lazy(() => import('./views/Terms'));
const PrivacyPage = lazy(() => import('./views/Privacy'));
const GDPRPage = lazy(() => import('./views/GDPR'));
const ServicesPage = lazy(() => import('./views/ServicesPage'));

// Lazy load service pages
const ConsultingPage = lazy(() => import('./views/services/ConsultingPage'));
const CommerceAsAServicePage = lazy(() => import('./views/services/CommerceAsAServicePage'));
const CustomDevelopmentPage = lazy(() => import('./views/services/CustomDevelopmentPage'));
const DataIntegrationsPage = lazy(() => import('./views/services/DataIntegrationsPage'));
const WebsiteAnalyticsPage = lazy(() => import('./views/services/WebsiteAnalyticsPage'));
const BeautyServicesPage = lazy(() => import('./views/services/BeautyServicesPage'));

// Lazy load product pages
const PIMPage = lazy(() => import('./views/products/PIMPage'));
const OMSPage = lazy(() => import('./views/products/OMSPage'));
const CDPPage = lazy(() => import('./views/products/CDPPage'));

// Lazy load blog pages
const BlogV2 = lazy(() => import('./views/BlogV2'));
const BlogContainerV2 = lazy(() => import('./views/blog/BlogContainerV2'));
const OmnichannelArticle = lazy(() => import('./views/blog/OmnichannelArticle'));
const OMSArticle = lazy(() => import('./views/blog/OMSArticle'));
const PIMArticle = lazy(() => import('./views/blog/PIMArticle'));
const CDMArticle = lazy(() => import('./views/blog/CDMArticle'));
const EcommerceArticle = lazy(() => import('./views/blog/EcommerceArticle'));
const ChallengesArticle = lazy(() => import('./views/blog/ChallengesArticle'));
const EmbracingOmnichannelArticle = lazy(() => import('./views/blog/EmbracingOmnichannelArticle'));
const DataSecurityArticle = lazy(() => import('./views/blog/DataSecurityArticle'));
const BlogContainer = lazy(() => import('./views/blog/BlogContainer'));

// Lazy load other pages
const MerchantApplicationPage = lazy(() => import('./views/MerchantApplicationPage'));
const DeleteAccountPage = lazy(() => import('./views/DeleteAccountPage'));

const LoadingSpinner = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <CircularProgress />
  </Box>
);

function App() {
  const LPtheme = createTheme(getLPTheme('dark'));

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <Router>
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/demo" element={<RequestDemoPage />} />
            <Route path="/faqs" element={<FAQsPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/careers" element={<CareersPage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/gdpr" element={<GDPRPage />} />
            <Route path="/services" element={<ServicesPage />} />
            <Route path="/services/consulting" element={<ConsultingPage />} />
            <Route path="/services/commerce-as-a-service" element={<CommerceAsAServicePage />} />
            <Route path="/services/custom-development" element={<CustomDevelopmentPage />} />
            <Route path="/services/data-integration" element={<DataIntegrationsPage />} />
            <Route path="/services/website-analytics" element={<WebsiteAnalyticsPage />} />
            <Route path="/services/beauty" element={<BeautyServicesPage />} />

            <Route path="/products/product-information-management" element={<PIMPage />} />
            <Route path="/products/order-management-system" element={<OMSPage />} />
            <Route path="/products/customer-data-platform" element={<CDPPage />} />

            {/* Blog routes - V2 (new structure) */}
            <Route path="/blog/v2" element={<BlogV2 />} />
            <Route path="/blog/v2/:slug" element={<BlogContainerV2 />} />

            {/* Blog routes - V1 (legacy) */}
            <Route path="/blog/omnichannel-success" element={<OmnichannelArticle />} />
            <Route path="/blog/centralized-oms-benefits" element={<OMSArticle />} />
            <Route path="blog/unlocking-pim-power" element={<PIMArticle />} />
            <Route path="blog/harnessing-cdm-power" element={<CDMArticle />} />
            <Route path="blog/ecommerce-management-solution" element={<EcommerceArticle />} />
            <Route path="blog/ecommerce-challenges" element={<ChallengesArticle />} />
            <Route path="/blog/embracing-omnichannel" element={<EmbracingOmnichannelArticle />} />
            <Route path="/blog/data-security-in-ecommerce" element={<DataSecurityArticle />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/blog/:headline" element={<BlogContainer />} />
            <Route path="/delete-account" element={<DeleteAccountPage />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="/apply" element={<MerchantApplicationPage />} />
          </Routes>
        </Suspense>
      </Router>
      <CookieConsent />
    </ThemeProvider>
  );
}

export default App;
