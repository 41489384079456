import * as React from 'react';
import { Box, Container, Typography, Button, Slide } from '@mui/material';
import { styled } from '@mui/material/styles';

const BannerWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  background: theme.palette.background.paper,
  color: theme.palette.text.primary,
  position: 'fixed',
  bottom: 0,
  left: 0,
  zIndex: 1300,
  boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
  borderTop: `1px solid ${theme.palette.divider}`,
}));

export default function CookieConsent() {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    // Check if user has already consented
    const hasConsented = localStorage.getItem('cookieConsent');
    if (!hasConsented) {
      setShow(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShow(false);
  };

  if (!show) return null;

  return (
    <Slide direction="up" in={show} mountOnEnter unmountOnExit>
      <BannerWrapper>
        <Container
          maxWidth={false}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            py: 2,
            px: { xs: 2, sm: 3 },
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: 2, sm: 0 },
          }}
        >
          <Typography
            variant="body2"
            sx={{
              textAlign: { xs: 'center', sm: 'left' },
              mr: { sm: 4 },
            }}
          >
            We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies.{' '}
            <Button
              size="small"
              sx={{ 
                textTransform: 'none',
                textDecoration: 'underline',
                p: 0,
                minWidth: 'auto',
                verticalAlign: 'baseline',
                color: 'inherit'
              }}
              onClick={() => window.location.href = '/privacy'}
            >
              Learn more
            </Button>
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, whiteSpace: 'nowrap' }}>
            <Button
              variant="outlined"
              size="small"
              onClick={handleAccept}
              sx={{ minWidth: 100 }}
            >
              Accept
            </Button>
          </Box>
        </Container>
      </BannerWrapper>
    </Slide>
  );
}
