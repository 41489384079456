// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const firestore = getFirestore(app);
export const functions = getFunctions(app);

const isLocalEnvironment = process.env.NODE_ENV === 'development';

export const logPageView = (pageName) => {
  const hasNotConsented = localStorage.getItem('cookieConsent') === 'false';
  
  if (!isLocalEnvironment && !hasNotConsented) {
    logEvent(analytics, 'page_view', {
      page_title: pageName,
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
    
    if (window.lintrk) {
      window.lintrk('track', { conversion_id: pageName });
    }
  }
};

export const logButtonClick = (buttonName) => {
  if (!isLocalEnvironment) {
    logEvent(analytics, 'button_click', {
      button_name: buttonName,
    });
  }
};

export const logInputInteraction = (inputName, interactionType) => {
  if (!isLocalEnvironment) {
    logEvent(analytics, 'input_interaction', {
      input_name: inputName,
      interaction_type: interactionType,
    });
  }
};

export const logComponentVisible = (componentName) => {
  if (!isLocalEnvironment) {
    logEvent(analytics, 'component_visible', {
      component_name: componentName,
      timestamp: new Date().toISOString(),
    });
  }
};

export const logComponentHidden = (componentName, durationMs) => {
  if (!isLocalEnvironment) {
    logEvent(analytics, 'component_hidden', {
      component_name: componentName,
      duration_ms: durationMs,
    });
  }
};

export const logAbandonedForm = (formData) => {
  if (!isLocalEnvironment) {
    logEvent(analytics, 'abandoned_form', {
      form_name: 'Sign Up',
      email: formData.email,
      timestamp: new Date().toISOString(),
      ...formData,
    });
  }
};

export const logFormSubmission = (formName, formData) => {
  if (!isLocalEnvironment) {
    logEvent(analytics, 'form_submission', {
      form_name: formName,
      ...formData,
    });
  }
};

export const logError = (errorName, errorMessage) => {
  if (!isLocalEnvironment) {
    logEvent(analytics, 'error', {
      error_name: errorName,
      error_message: errorMessage,
    });
  }
};
